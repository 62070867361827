import { Row, Col } from 'react-bootstrap'
import { Children } from 'react'
import {SectionContainer} from 'reveal-components'

import styles from './AlternateSectionContainer.module.scss'

const AlternateSectionContainer = (props) => {
    const children_array = Children.toArray(props.children)
    return (
        <SectionContainer headline={props.headline}>
            <Row className={styles.container}>
                {children_array.map((child, index) => {
                    return <Col className={props.childrenClassName} key={index}>{child}</Col>
                })}
            </Row>
        </SectionContainer>
    )
}

export default AlternateSectionContainer