export const testi = [
    {
        headline: 'Cos’è l’allenamento della forza?',
        text: `E’ un attività fisica concepita per migliorare la muscolatura tramite l’allenamento di un
singolo gruppo muscolare o di un intera catena cinetica contro una resistenza esterna:
come può essere un peso esterno o il peso del proprio corpo, aiutando a sviluppare anche
una miglior meccanica del corpo,apportando benefici all’equilibrio,alla cordinazione e alla postura.
Il principio di base che è quello di applicare un carico e sovraccaricare il muscolo in modoche debba adattarsi a diventare più forte.
I benefici non si fermano a diventare più forte ma si ampliano a proteggere la massa
muscolare e la salute delle ossa,mantenere equilibrato il peso corporeo diminuendo la
massa grassa e aumentando quella magra.
Si è dimostrato in oltre che nelle persone anziane a più alto rischio di caduta a causa di
un peggior funzionamento fisico,l’allenamento della forza riduce il rischio di cadere del
40% rispetto a chi non ha fatto esercizio sull’ allenamento della forza.
I benefici dello strength training..
Uno studio publicato sul Journal of Bone and Mineral Research nell’ottobre 2017 ci diceche a 30 anni iniziamo a perdere dal 3 al 5% di massa magra all’anno grazie all’invecchiamento e che per evitare tutto questo bastano 30 minuti,due volte a settimana di allenamento della forza ad alta intensità e/o ad impatto,dimostrando il miglioramento delle prestazioni funzionali (quotidiane),così come la densità ossea e la struttura e la forza nelle donne in post-menopausa.
I parametri da rispettare in una buona programmazione specifica per la forza:
Per sviluppare un buon programma di forza abbiamo bisogno di scegliere i corretti esercizi
che mettono in difficolta il nostro SNC e i nostri muscoli, e di una programmazione che
faccia migliorare la nostra forza senza stressare il Sistema Nervoso Centrale,permettendo
allo stesso tempo di eseguire gli allenamenti specifici del nostro sport.
Scientificamente è stato dimostrato che gli esercizi più affini in una programmazione di
forza sono quelli che coinvolgono più articolazioni e muscoli possibili e quindi esercizi
multi-articolari che coinvolgono più articolazioni possibili.
Sicuramente i 3 esercizi che non possono mancare in una programmazione dello sviluppo
della forza sono:
Squat
Stacco
Panca
         
Invece all’interno di una programmazione ci sono delle colonne portanti che devono
essere sicuramente rispettate per far si che l’obiettivo venga raggiunto.
         
Numero di allenamenti: Allenamenti specifici che vanno da un minimo di 2 ad un
massimo di 4 a settimana.
         
Numero di esercizi: Da un minimo di 2 esercizi (Squat,Panca – Panca,Stacco – Squat-Stacco) ad un massimo di 5-6 esercizi che comprendino qualche complementare;
 Es: (Squat,Panca,Stacco,Military Press,Trazioni e/o Dips,Good Morning).`,
        img: '/img/articles/1.webp',
        fallback: '/img/articles/articoli1.jpeg',
        left: true
    },
    {
        headline: 'Latte e Osteoporosi',
        text: `Nonostante le opinioni diffuse anche fra taluni medici e nutrizionisti, e al di là delle divulgazione operate dalle pubblicità, il consumo di latte non previene l'osteoporosi, né aiuta i soggetti affetti da questa patologia, inclusi quelli più a rischio per motivi fisiologici, come le donne in menopausa. Infatti, come afferma l'Harvard Nurses' Health Study , che ha seguito clinicamente oltre 75.000 donne per dodici anni, ha mostrato che l'aumentato consumo di latte non avrebbe alcun effetto protettivo sul rischio di fratture. Anzi, l'aumentata introduzione di calcio attraverso latte e latticini era associato con un rischio di fratture più elevato. Le proteine contenute nel latte hanno infatti un alto grado di acidità, e ciò significa che, per quanto il latte possa fornire calcio e vitamina D, provocherà anche delle reazioni fisiologiche volte a tamponare l'acidità provocata dall'assorbimento delle suddette proteine con conseguente impoverimento dei depositi di calcio contenuti nelle ossa. Uno Studio Australiano  è pervenuto al medesimo risultato. Inoltre altri Studi  non hanno evidenziato alcun effetto protettivo sull'osso da parte del Calcio proveniente dai derivati del latte. Per ridurre il rischio di osteoporosi, va ridotta l'assunzione con la dieta di Sodio e di Proteine animali , aumentato il consumo di frutta e verdura, l'attività fisica, e va assicurato un adeguato introito di Calcio da fonti vegetali, 

        FONTE: WIKIPEDIA`,
        img: '/img/articles/2.webp',
        fallback: '/img/articles/articoli2.jpeg',
        left: false
    },
    {
        headline: 'Cos\'e\' il BIIO?',
        text: `ll BIIO è una tipologia di allenamento molto performante per il corpo. La grande intensità con la quale si svolge ogni seduta permette al corpo di trasformarsi molto di piu rispetto ad un allenamento classico, questo perchè innalzando il livello di testosterone NATURALMENTE, con i carichi e ciclizzando l'allenamento, aumenteremo gradualmente la forza che poi useremo per tonificare e modellare il corpo, tutto questo è dovuto anche col riposo che nelle palestre di solito non si fa!
Breve : Ogni seduta deve durare al massimo 60  minuti, dopo di che tende a innalzarsi il cortisolo che se in eccesso tende a catabolizzare la struttura muscolare.
Intenso : Avendo a disposizione solo quell ora , l ‘ allenamento sarà organizzato con esercizi multiarticolari di base, così che l’atleta possa utilizzare carichi estremamente pesanti per esercitare il maggior stress possibile al muscolo.
Infrequente : Le sedute di allenamento devono essere molto più rare delle solite 4-7 che propongono in quasi ogni palestra; in altre parole i vostri allenamenti devono essere  assolutamente INFREQUENTI, questo per il principio della Supercompensazione (meccanismo fisiologico per cui il nostro corpo si adatta alla sollecitazioni esterne).
Organizzato : Ogni allenamento viene organizzato tramite delle schede in cui viene scritto tutto il necessario e ad ogni seduta vengono confrontati i risultati per osservare e studiare il progresso.`,
        img: '/img/articles/3.webp',
        fallback: '/img/articles/articoli3.jpeg',
        left: true
    },
    {
        headline: 'UNA SCHEDA PER RIPRENDERE ATTIVITA’ FISICA',
        text: `Settembre, si ricomincia il solito tran tran, ma si ritorna dalle ferie con tanti buoni propositi.
 
Ecco dunque una scheda di allenamento molto semplice per chi vuole riprendere il ritmo.
         
Si inizia sempre con un po’ di riscaldamento, bastano 2 o 3 minuti di cyclette per aumentare la temperatura corporea e prepararsi all’allenamento vero e proprio. Non serve correre come dei criceti sul tappeto.
         
Primo esercizio: chest press. Per allenare il muscolo pettorale, i tricipiti in maniera indiretta e un po’ di spalle. Facciamo 3 serie da 10 ripetizioni con una pausa di circa un minuto o meno.
Il carico? Quello che ci permette di arrivare a 10 ripetizioni con un po’ di fatica.
         
Secondo esercizio: lat machine con triangolo. Alleniamo la schiena e i bicipiti. Manteniamo le 3 serie da 10 ripetizioni.
         
Terzo esercizio: pressa orizzontale. Per le gambe ed i glutei. Carico pesante ma non troppo. 3 serie da 10.
         
Quarto esercizio: spalle alla shoulder press. Spingiamo in alto queste braccia, mi raccomando le spalle sempre basse. 3 serie da 10.
         
Ultimo esercizio: addominali. Vanno fatti ad ogni allenamento, anche se “gli addominali si fanno a tavola“, crunch alla macchina, 2 serie da 15.
Agli esercizi per gli addominali dedicherò un post più avanti.
         
Alla fine ancora un po’ di cyclette per il defaticamento.  3 minuti e poi una bella doccia.`,
        img: '/img/articles/4.webp',
        fallback: '/img/articles/articoli4.jpeg',
        left: false
    },
    {
        headline: 'VITAMINA C',
        text: `E’ considerato, a ragione, il più importante elemento nutritivo per la nostra salute.
L’uomo deve assumerlo con l’alimentazione perché l'organismo non riesce a produrlo.
                 
Le funzioni principali
                 
La vitamina C svolge varie  importanti funzioni biologiche, le principali:
                
- sintesi del collagene, proteina fondamentale che entra nella formazione dei tessuti connettivali, delle cartilagini, delle ossa e che ha proprietà "cementanti le cellule". Essa dà solidità, resistenza ed elasticità ai tessuti del nostro corpo: vasi sanguigni, ossa, pelle, tendini, ecc
                
- trasformazione del colesterolo in acidi biliari (importanti questi ultimi per la  digestione degli acidi grassi)
                
- assorbimento ed utilizzazione del ferro
                
- fattore essenziale nel metabolismo della tiroxina, aminoacido da cui derivano i neurotrasmettitori adrenalina e  noradrenalina e di ossidazione di altri aminoacidi
                
- aumenta le riserve epatiche e muscolari del glicogeno
                
- azione stimolante l'attività fagocitaria dei leucociti sulle funzioni del sistema reticolo-endoteliale,  quindi importante  per il sistema immunitario
                
- azione antiossidante, anti radicali liberi in sinergia con la vitamina E
                
- conversione dell'acido folico in acido folinico.
                
Inoltre possiede l'importante proprietà di ridurre la formazione nell'organismo di nitrosamine, sostanze tossiche e cancerogene che derivano dai nitrati, sostanze preservanti e aromatizzanti presenti in certi cibi quali le salsicce, le carni insaccate, la carne in scatola, il pesce affumicato.
                
Agisce come fattore antistress, antifatica e di stimolo generalizzato sia a livello fisico che mentale poiché stimola la funzione della corteccia surrenalica.
Conferisce una maggiore resistenza  alle malattie infettive durante le quali nell'organismo vi è un abbassamento della concentrazione proprio della vitamina C.
                 
E' una vitamina termolabile per cui è facilmente disattivata con la cottura dei cibi che la contengono, è preferibile quindi assumere gli alimenti ricchi in vitamina C crudi e non cotti.
                 
                 
Se il collagene scarseggia, le ossa diventano fragili (= osteoporosi), le articolazioni si indeboliscono (= artrosi) i vasi sanguigni perdono elasticità (= ipertensione sanguigna) e subiscono lesioni di vario tipo (= arteriosclerosi, infarto, ictus), la pelle diventa vecchia e rugosa, ecc... Si dice che siano i cosiddetti “segni dell’età”; ma veramente l’età non c’entra molto. Sono i segni di  carenza cronica di vitamina C.
                 
                  
Questa vitamina è forse la più conosciuta di tutte, grazie anche alla sua provata capacità di prevenire i raffreddori. Una funzione meno conosciuta ma non per questo meno importante, è l'azione benefica sui dolori di schiena o lombari.
                 
C'è da aggiungere che situazioni di stress depauperano la nostra riserva di vitamina C,   esponendo il nostro corpo a vari malanni fisici, non ultimo, naturalmente, il mal di schiena.
                 
Alte somministrazioni non danno luogo a ipervitaminosi. Ciò infatti è quasi impossibile anche per dosaggi notevolmente alti, in quanto la sostanza in eccesso viene automaticamente espulsa dal nostro organismo, senza quindi alcun danno di accumulo. Basilare comunque il fatto che l'acido ascorbico non sia assolutamente sintetizzabile dal nostro organismo, che lo può ricevere solo dall'alimentazione. Per quanto riguarda il suo livello tossico, bisogna dire che l'acido ascorbico è considerato un composto fra i più altamente innocui che esistano in natura. A tutt'oggi non è stato mai accertato un caso di ipervitaminosi da vitamina C. Per scrupolo, una dose giornaliera limite può essere indicata in quantità di circa 4-5 gr.
                 
Fonti bibliografiche:
Tutto integratori alimentari 2006 di Claudio Tozzi
Come vivere senza malattie e senza medicine  di Alberto  R. Mondini                
Linus Pauling
                 
«Uno studio sorprendente pubblicato negli Annals of Internal Medicine del 2004 ha rivelato che la vitamina C endovena può portare a concentrazioni di vitamina C nel sangue sufficienti per uccidere le cellule cancerose e ha sollecitato il riesame della terapia con vitamina C [Annals Internal Medicine, vol. 140, 2004, pp. 533-537]. La vitamina C endovenosa è tossica solo per le cellule cancerose, non per quelle sane. La medicina moderna ha reagito a questo studio ignorandolo. Adesso uno studio pubblicato nel Canadian Medical Association Journal mostra in maniera convincente come la vitamina C per via endovenosa abbia represso in maniera documentata il cancro avanzato in tre casi riguardanti cancro alla vescica, polmone, rene e linfoma. Questi tre casi sono stati trattati con i rigidi standards dei case report stabiliti dall'U.S. National Cancer Institute. Con la terapia di vitamina C endovenosa i tempi di sopravvivenza sono stati di 4, 9 e 10 anni [Canadian Medical Assn Journal, vol. 174, 2006, pp.  937-942]! Per contro, oggi gli oncologi prescrivono farmaci chemioterapici che, nella migliore ipotesi, prolungano la vita dei pazienti di cancro di 1-2 mesi a un costo di 5.000 dollari al mese».
Sardi, Bill, “We Let Them Die – 15 Million of Them”, da www.knowledgeofhealth.com, 28 marzo 2006.`,
        img: '/img/articles/5.webp',
        fallback: '/img/articles/articoli5.jpeg',
        left: true
    },
    {
        headline: 'Allenamento con i pesi per le donne',
        text: `Ragazze, date un occhiata attorno a voi in palestra. Quante donne grosse e super muscolose vedete che sollevano pesi? Probabilmente non molte, a meno che  non vi stiate allenando in una palestra frequentata da dopati. Adesso, guardatevi ancora attorno. Quante donne con corpi tonici, belli e  femminili vedete sollevare pesi? Immagino molte di più. Queste signore, che hanno una solida base muscolare, sembrano ancora donne e sono anche molto attraenti. Come si allenano queste donne per ottenere questa forma? Ore di tapis roulant? No. Molto tempo sulla cyclette? Probabilmente no. Step? Possibilità molto remota.
 
In realtà queste donne hanno costruito i loro fisici sexy e femminili allenandosi con i pesi esattamente come fanno gli uomini.
        
            
            
Se sei una donna e vuoi tonificare e modellare il tuo corpo, scendi da quel tappeto o cyclette e inizia a sollevare pesi, solo così riuscirai a raggiungere i tuoi obiettivi. Molte donne pensano che non dovrebbero sollevare pesi come gli uomini perché diventerebbero corpulente e “non femminili”. In realtà non è così che si diventa enormi. Infatti, la maggior parte degli uomini, uomini che producono più testosterone e con un potenziale di sviluppo muscolare maschile, hanno grandi difficoltà nel costruire massa muscolare. Se questo è vero per gli uomini, vale  ancora di più per le donne, che producono molti estrogeni.
            
Quindi ragazze, smettetela di preoccuparvi di diventare enormi. Quelle donne che sanno come allenarsi con i pesi in maniera efficace sono molto più attraenti (sia con i vestiti che senza) rispetto alle donne che trascorrono molte ore facendo attività aerobica.
        
L'allenamento  con i pesi è l’unico modo con il quale potrete scolpire la vostra figura ed avere braccia toniche e glutei sodi. 
        
        
Fonte:  Cassandra Forsythe sito highintesityitalia`,
        img: '/img/articles/6.webp',
        fallback: '/img/articles/articoli6.jpeg',
        left: false
    },
    {
        headline: 'LA PALEO DIETA',
        text: `L'immagine raffigura la piramide alimentare  dei cibi approvati e proposti  dalla Paleo Dieta. 
Ho adottato questo stile di alimentazione semplice ed efficace, sano ed equilibrato, in grado di fornirci tutti gli elementi nutritivi di cui abbiamo bisogno, mantenerci in ottima forma e pieni di energia. Dopo il breve periodo iniziale in cui vi sembrerà impossible vivere senza la pasta o il latte o il pane, sarete talmente soddisfatti dei risultati che non vorrete più tornare alle vecchie abitudini.
        
Cos'è la Paleo Dieta
La Paleo Dieta intende riproporre il tipo di alimentazione che caratterizzava le popolazioni umane vissute nel periodo precedente la scoperta dell'agricoltura, avvenuta circa 10.000 anni fa. Per circa due milioni di anni, gli uomini trassero sostentamento da caccia, pesca e raccolta di vegetazione spontanea; a sostenere la Paleo Dieta è pertanto l'assunto che a livello genetico e fisiologico l'uomo non ha subito grossi mutamenti dal paleolitico ad ora, sebbene si sia evoluto dal punto di vista culturale, tecnologico e scientifico.
Di conseguenza, la corretta alimentazione si dovrebbe basare su cibi che erano reperibili prima dello sviluppo delle tecniche agricole, cioè su carne, pesce, crostacei, uova, bacche, frutti, verdure e frutta secca.
        
Cosa mangiare
La Paleo Dieta consiglia di mangiare cibi quanto più possibile vicini allo stato naturale: carne  meglio se di animali selvatici o allevati bradi senza l'utilizzo di granaglie; pesce, anche in questo caso preferendo pesci pescati, frutti di mare, crostacei, molluschi, uova, frutta e verdura di ogni tipo, scelta tra i prodotti di stagione, moderate quantità di frutta secca. L'unico olio consentito è l'olio extravergine d'oliva. Notevole importanza rivestono i pesci grassi ed il pesce azzurro, quindi sgombro, tonno, sardine, alici, in quanto contengono rilevanti quantità di acidi grassi omega-3.
Per quanto riguarda le percentuali di macronutrienti, non vengono fissate in modo preciso (come avviene per esempio nella Dieta Zona) ma viene fornito un range: le proteine rappresentano dal 20% al 35% delle calorie, i grassi dal 30% al 60% ma anche 80% o oltre per alcuni soggetti, i carboidrati dal 20% al 35% delle calorie.  
        
Cosa non mangiare
Tutto quanto non era disponibile nel Paleolitico e che dunque risulta estraneo al genoma dell'uomo, quindi: i cereali e i loro derivati, i legumi (compresa la soia), il latte e i suoi derivati. Da eliminare anche   caffè, cacao, vino, aceto e sale. Il motivo dell'eliminazione del sale risiede nel fatto che a coprire il fabbisogno corporeo di sodio basta quello contenuto nei cibi e l'aggiunta di altro sale comporterebbe un dannoso squilibrio tra sodio e potassio.
Vanno eliminati anche gli oli di mais e di semi e la margarina: i primi poiché ricchi di acidi grassi omega-6, che hanno effetto infiammatorio, la seconda perché è costituita da grassi idrogenati, molto pericolosi per la salute.
        
Punti di forza
1) Seguendo il modello paleolitico si introducono con l'alimentazione elevate quantità di vitamine e cofattori vitaminici, minerali, antiossidanti, contenuti nella frutta, nella verdura e nelle carni.
2) Inoltre, l'eliminazione degli oli di semi e il consumo abituale di pesce azzurro permette di raggiungere il giusto equilibrio tra acidi grassi omega-3 ed omega-6, sebbene appaia molto difficile oggigiorno ottenere il rapporto ideale tra omega-3 ed omega-6 senza ricorrere all'uso di integratori: si calcola infatti che nel Paleolitico tale rapporto fosse 1:1.
3) L'abbondante consumo di verdura e frutta e l'esclusione di cereali e latticini apporta benefici in quanto produce nel corpo un ambiente alcalino, con effetti protettivi per le ossa e la salute in genere.
        
Critiche
1) Questa dieta viene accusata di essere iperproteica; in realtà, essa non limita i carboidrati, consiglia solo di consumare i carboidrati della frutta e della verdura, che sono a basso indice glicemico e non dei cereali e dei loro derivati.
2) Un'altra critica che le viene mossa riguarda la mancanza di calcio, data l'esclusione dei latticini. C'è da ricordare in proposito come i latticini, benché ricchi di calcio, siano molto acidificanti e dunque rendano difficoltosa l'assimilazione di questo minerale oltre ad alterare l'equilibrio calcio/magnesio. Al contrario, una dieta ricca di verdura e frutta e dunque alcalinizzante come la Paleo Dieta avrebbe effetto protettivo nei confronti dell'osteoporosi.
3) Un'ultima obiezione afferma che nel corso del tempo è possibile che ci siano state modificazioni riguardo al patrimonio genetico umano, per cui l'uomo si sarebbe adattato al consumo di latticini e/o di cereali. La diffusione di intolleranze al latte e al glutine porta a mettere in discussione il fondamento di questa ipotesi. Oltre al fatto che le ricerche di Eaton e Cordain hanno evidenziato una modifica nel genoma umano da 40.000 anni a questa parte di solo lo 0,02%. Proprio Cordain è atteso  in Italia, per una conferenza sulla Paleo Diet che si terrà a Roma il 16/17 aprile 2011.
        
Bibliografia
Dr. Loren Cordain, "The Paleo Diet"
Dr. Loren Cordain e Joe Friel, "The Paleo Diet for Athletes"`,
        img: '/img/articles/7.jpg',
        fallback: '/img/articles/articoli7.jpeg',
        left: true
    },
    {
        headline: 'Tecniche di allenamento : Squat a 20 ripetizioni (don\'t try this at home )',
        text: `Ecco l’esecuzione dello squat a 20 ripetizioni : 

“Invece di effettuare le solite 8 o 10 ripetizioni, si arriva invece a 20 ripetizioni con lo stesso peso. Il peso da utilizzare deve corrispondere al 75 % del nostro massimale. Come si può arrivare alla ventesima se già all’ottava ( utilizzando il 75 %)  dovremmo essere già a cedimento muscolare? Beh semplice …(ma non troppo ): arrivati all’ottava ripetizione ci si ferma per 4 secondi, mantenedo in bilancere sulle spalle, si effettuano due o tre respiri profondi e poi si continua. Quei respiri sono sufficienti per farci acquistare la forza necessaria per fare altre due o tre ripetizioni; dopodiché si continua fermandosi per altri  4 secondi,  fino ad arrivare alla ventesima."
        
Lo squat a 20 ripetizioni effettuato al proprio limite assoluto è così produttivo da essere praticamente indispensabile alla maggior parte delle routines natural.`,
        img: '/img/articles/8.webp',
        fallback: '/img/articles/articoli8.jpeg',
        left: false
    },
    {
        headline: 'ALLENARSI CON I PESI',
        text: `Perché dovrei andare in palestra e fare fatica a sollevando pesi?
Preferirei fare i corsi di gruppo e poi mi piace camminare all’aria aperta, che cosa potrei ottenere da un allenamento con i pesi?
Queste sono alcune delle domande che mi sento rivolgere.
            
Cercherò di rispondere qui.
        
La palestra è un luogo dove troviamo delle macchine e dei “pesi liberi” (oltre a tapis roulant, cyclette, step, vogatori, ecc ecc) e con questi pesi si lavora, facendo determinati movimenti (esercizi) in un determinato modo (tecnica) per un numero variabile di serie e ripetizioni (volume di allenamento).
I motivi per cui ci si dovrebbe allenare con i pesi sono diversi. Uno dei tanti, secondo me il più importante, è che i pesi ci permettono di scaricare tutta la tensione accumulata durante la giornata.
Qualcuno potrebbe obiettare che anche con i corsi di gruppo, con le partite a tennis o di calcio o con altri sport si scarichi la tensione, si certo è vero, ma l’unica attività che ci permette di modificare la composizione corporea, aumentare la massa muscolare, migliorare il tono muscolare, diventare più forti, è l’ allenamento con sovraccarico progressivo, ovvero sollevare pesi.
Quando proverete l’ansia per la prova costume, ricordate che sollevare pesi potrà essere il vostro alleato numero uno. Insieme ad una dieta specifica, naturalmente.`,
        img: '/img/articles/9.webp',
        fallback: '/img/articles/articoli9.jpeg',
        left: true
    },
    {
        headline: 'Tecniche di allenamento : Protocollo TABATA',
        text: `Il metodo di allenamento Tabata è stato sviluppato dal Dottor Izumi Tabata presso l’Istituto Nazionale della Salute e dell’Alimentazione di Tokio in Giappone. È un tipo di allenamento molto efficace, basato su criteri scientifici, in grado di produrre un dimagrimento sostanziale e duraturo, mantenendo un ottimo strato di massa magra. 
I metodi d’allenamento ‘brucia grassi’ più conosciuti prevedono di lavorare con esercizi aerobici tipo corsa o cyclette, ad una frequenza cardiaca costante che si attesta intorno al 65/75% della FC Max. Sino ad oggi gli studi in materia dimostravano che questo tipo di lavoro era più efficace per bruciare i grassi. Le nuove ricerche provano invece che questo non è completamente vero. A parità di tempo di lavoro, l’allenamento ad alta intensità brucia effettivamente una quantità di grasso inferiore rispetto al lavoro costante ad intensità media. Nel complesso però, il lavoro ad alta intensità è in grado di accumulare un dispendio calorico totale fino al 50% in più rispetto all’allenamento classico. La differenza sostanziale è che mentre gli esercizi ad intensità costante bruciano solamente durante l’allenamento, quelli ad alta intensità consumano calorie anche dopo l’allenamento, avvalendosi del così detto effetto boomerang. Quello che in fondo succede allenandosi con i pesi in sala attrezzi. Molti associano il dimagrimento esclusivamente all’attività cardio vascolare tralasciando completamente qualsiasi altra forma di esercizio. L’attività cardio è una componente importante nel processo di dimagrimento, ma non è la sola in grado di creare miglioramenti. Per un lavoro completo, infatti, l’attività aerobica dovrebbe essere abbinata ad un allenamento con opposizione di resistenza eseguito in palestra. 
In pratica Il Protocollo Tabata  si compone così:
Il tipo di allenamento non è importante, potete scegliere lo sport o l’esercizio che più vi piace, ma più sarà di tipo ripetitivo e più sarà facile massimizzare lo sforzo e, quindi, i risultati.
E’ composto da otto serie/cicli di trenta secondi ciascuna (che, per l’appunto, fanno proprio 4 minuti)
Ogni serie/ciclo si compone così:
20 secondi in cui lo sforzo deve essere al massimo e 10 in cui bisogna stare in completo riposo.
Semplice no ?`,
        img: '/img/articles/10.webp',
        fallback: '/img/articles/articoli10.jpeg',
        left: false
    },
    {
        headline: 'GLI OVER 40',
        text: `In quanto personal trainer, tutti i giorni cerco notizie, mi informo, studio quello che riguarda il mondo del fitness e del sollevamento pesi in generale.

Su youtube ci sono un sacco di ragazzi che postano video su allenamenti, alimentazione ed esperienze sui risultati ottenuti.
        
Ho notato una cosa però: l’età media di questi youtubers è di circa 25 anni, quindi con un’esperienza alle spalle al massimo di 5-7 anni nel campo del fitness. Questi ragazzi si propongono e si relazionano con persone della loro età.
        Sono tutti molto preparati, ma gli youtubers over 40 sono veramente pochi.
        
Cosa dovrebbe fare un over 40 per avere dei risultati apprezzabili con il sollevamento pesi?
        
In base alla mia esperienza, ho 45 anni e mi alleno da 27, quello che ci differenzia dai ragazzi più giovani è il recupero muscolare; dopo un allenamento veramente intenso ci vuole qualche giorno in più prima di allenarsi nuovamente in maniera intensa ed avere buoni risultati.
        
Anche riguardo l’alimentazione, i tempi sono un po’ più lunghi per avere una diminuzione della massa grassa, ma si arriva comunque ad ottenere la forma desiderata.
        
Un’altra differenza è il riscaldamento: per l’over 40 riscaldamento e mobility (o stretching, che dir si voglia) sono assolutamente OBBLIGATORI. Evitano dolori e peggio ancora, stiramenti o strappettini vari e ci consentono di allenarci in maniera continuativa senza fastidiosi inconvenienti.`,
        img: '/img/articles/11.webp',
        fallback: '/img/articles/articoli11.jpeg',
        left: true
    },
    {
        headline: 'LA FORZA',
        text: `“La forza si può paragonare ad un bicchiere che può essere riempito con altre qualità. Più grande è il bicchiere, più resistenza, abilità sportiva, perdita di grasso, ecc., esso può contenere.” (Pavel Tsatsouline)
 
 
 
Perché ci si iscrive in palestra? Per rimettersi in forma? Per perdere peso? Per aumentare la massa muscolare? Per vedersi meglio allo specchio? Per migliorare le prestazioni sportive?
            
        
Gli obiettivi possono essere molteplici, ma l’unico modo per raggiungerli è diventare più FORTI.
            
Si possono passare ore e ore su un tapis roulant, fare stretching, frequentare corsi, ma se si è intenzionati a cambiare il proprio aspetto davanti allo specchio, allora bisogna sollevare pesi, pesi sempre più pesanti, perché il nostro corpo è furbissimo e si adatta al carico in men che non si dica.
            
        
Certo, va curata anche l’alimentazione, ma per ottenere risultati in breve tempo, quei pesi vanno sollevati. Dopotutto, il muscolo brucia più calorie a riposo, quindi più muscoli si hanno, più facilmente si dimagrisce.
            
        
Ho allenato un nuotatore che all’inizio era scettico riguardo al lavoro con i pesi.
            
Diventando più forte ha ottenuto movimenti di braccia e gambe più potenti, aumentando di conseguenza la sua velocità in vasca. Ad ogni allenamento mi riferiva del miglioramento delle sue performance.
            
        
Chiunque può trarre giovamento dall’aumento della forza, anche chi non pratica sport a livello agonistico.`,
        img: '/img/articles/12.webp',
        fallback: '/img/articles/articoli12.jpeg',
        left: false
    },
    {
        headline: 'Lo scarico: esempio pratico',
        text: `Manchiamo dalla palestra da una settimana per motivi di lavoro. Innervositi da
un’assenza involontaria così lunga che “ci ha fatto sicuramente perdere massa e forza”, carichiamo
sfiduciati il solito peso sul bilanciere della panca. Normalmente
con quei chilogrammi facevamo 8 ripetizioni, adesso però... Iniziamo ed arriviamo a
sette, otto (“mah, com’è possibile?”), nove e dieci! Ci sediamo sulla panca stupefatti e
nel frattempo passa l’amico che non ci vede da una settimana e ci dice: “però, stai
caricando e infatti ti vedo in forma, stai veramente bene, bello pompato!”. Gli rispondiamo,
sempre più sorpresi: “veramente, non mi alleno da una settimana, ti sembra
che...”. L’incredibile è successo, ma ci diciamo che è un caso o che il nostro amico ha
le allucinazioni e andiamo avanti ad allenarci per 6 volte a settimana, senza MAI fare
alcun periodo di recupero per tutta la vita, perché è così che si deve fare...
Una delle devastanti conseguenze prodotte dalla mancata applicazione delle più
basilari regole della scienza sportiva al bodybuilding è senza dubbio la totale assenza
nei praticanti della mentalità del recupero. Dopo anni e anni di schede scandalose che
l’allievo svolge anche tutti i giorni e per mesi di seguito, è normale essere guardati con
un misto di sconcerto e commiserazione, se provi a dire di abbassare il peso per alcuni periodi.
In tutti gli sport la periodizzazione e quindi il recupero, è considerata la base essenziale per ottenere i migliori risultati.
        
Fonte : la scienza del natural bodybuilding C.Tozzi 
        `,
        img: '/img/articles/13.webp',
        fallback: '/img/articles/articoli13.jpeg',
        left: true
    },
    {
        headline: 'GLI ISTUTTORI IN PALESTRA',
text: `Quali sono i compiti di un istruttore in palestra? I primi che mi vengono in mente sono la preparazione delle schede, la spiegazione degli esercizi, il supporto alle macchine.
E questo è anche ciò che si aspetta un cliente.
Dando per scontate una preparazione tecnica sufficiente, una certa professionalità ed una buona predisposizione, quanti istruttori svolgono con passione i propri compiti? Io ne ho conosciuti tanti ma, purtroppo, come capita in ogni settore lavorativo, ci sono anche delle figure meno brillanti nel firmamento delle palestre.
Chi lavora a contatto con il pubblico e per di più ne è responsabile per quanto riguarda l’incolumità fisica , non può sottrarsi alla sorveglianza ed al supporto di chiunque si trovi impegnato nello svolgimento di un esercizio, principiante o esperto che sia.
E’ la passione degli istruttori che fa la differenza tra una palestra ed un’altra.
Io sono un personal trainer, aiuto le persone a raggiungere la forma fisica che desiderano.
Vi siete mai trovati in difficoltà in una palestra tentando un massimale senza alcun aiuto ? Si? Anche io.
Proprio per questo ai miei clienti dedico sempre tutta la mia attenzione, mettendo al loro servizio non soltanto la mia preparazione tecnica e professionale, ma anche la mia sensibilità, empatia e passione.
Vorrei dare 3 consigli pratici a chi deve iscriversi in palestra o vuole scegliere un personal trainer.
Attenzione alle low cost
Informatevi sulla preparazione del personal trainer
Aspettate prima di acquistare integratori, se ve li propongono.`,
        img: '/img/articles/14.webp',
        fallback: '/img/articles/articoli14.jpeg',
        left: false
    },
]
