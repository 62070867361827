import Reviews from '../../components/sections/reviews/Reviews';
import './Recensioni.scss';

import { useState, useEffect } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import { listReviews } from '../../graphql/queries';

function Recensioni() {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    fetchReviews()
  }, [])

  async function fetchReviews() {
    try {
      const reviewsData = await API.graphql(graphqlOperation(listReviews))
      const reviews = reviewsData.data.listReviews.items
      reviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setReviews(reviews)
    } catch (err) { console.log(err) }
  }

  return (
    <Reviews headline="Dicono di me" reviewsData={reviews}/>
  );
}

export default Recensioni;
