
import { SectionContainer } from 'reveal-components';
import Article from '../../components/reusable/article/Article';

import {testi} from "./Testi.js";

const Articoli = () => {
    return (
        <SectionContainer headline={'I miei articoli'}>
            {testi.map((testo, index) => {
                    return (
                        <Article text={testo.text} headline={testo.headline} imgSrc={process.env.PUBLIC_URL + testo.img}
                        fallbackSrc={process.env.PUBLIC_URL + testo.fallback} imgLeft={testo.left} key={index} />
                    )
                }
            )}
        </SectionContainer>
    );
}

export default Articoli;