import React from "react";

import "./Error404.scss";

import { SectionContainer } from 'reveal-components';
import { Link } from "react-router-dom";

const Error404 = () => {
    return(
        <SectionContainer headline={'OPS... 404'}>
            <div className={'d-flex align-content-center justify-content-center'}>
                <Link className={"text-center anchor"} to="/">Ritorna alla Home</Link>
            </div>
        </SectionContainer>
    )
}

export default Error404;