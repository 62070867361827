import React from "react";

import { Row, Col } from 'react-bootstrap';

import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faDumbbell } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import Counter from "../../reusable/counter/Counter";

import styles from "./Counters.module.scss"

const Counters = () => {
    const anniDiAllenamento = new Date().getFullYear() - 1990;

    return (
        <Row className={styles.countersContainer}>
                <Col className={'col-6 col-md-3'}><Counter text="Anni di allenamento" start={0} end={anniDiAllenamento} ico={faCalendarPlus}/></Col>
                <Col className={'col-6 col-md-3'}><Counter text="Clienti soddisfatti" start={0} end={99} ico={faUsers}/></Col>
                <Col className={'col-6 col-md-3'}><Counter text="Competizioni" start={0} end={20} ico={faDumbbell}/></Col>
                <Col className={'col-6 col-md-3'}><Counter text="Certificazioni" start={0} end={40} ico={faFile}/></Col>
        </Row>
    )
}

export default Counters;