import './ChiSono.scss';

import OrganicBackSection from '../../components/sections/organicBackSection/OrganicBackSection';
import TimeLine from "../../components/sections/timeLine/TimeLine";

import { Col } from 'react-bootstrap';

function ChiSono() {
  return (
    <>
      <div className="chiSonoSectionContainer">
        <OrganicBackSection img={process.env.PUBLIC_URL + "/img/backgrounds/diplomi.svg"}>
          <Col className={"title-wrapper col-12 col-lg-7"}>
              <h2 className={"text-center"}>chi sono</h2>
              <p>Il fitness non è solo un obiettivo ma uno stile di vita; richiede impegno, disciplina e duro lavoro.<br/><br/>
              La strada per perdere peso, aumentare la massa muscolare, competere o rimanere in buona salute non è mai facile. A volte, tutto ciò di cui hai bisogno è qualcuno che ti sostenga con un buon supporto e motivazione.<br/><br/>
              Dal 2009, ho aiutato le persone a raggiungere i propri obiettivi, ottenere risultati e fare ciò che pensavano di non riuscire mai a fare.
              </p></Col>
          <Col className={"d-none d-lg-block col-lg-5"}>
            <img className={"img-silvio"} src={process.env.PUBLIC_URL + "/img/silvio/chi_sono.png"} alt="silvio porro"/>
          </Col>
        </OrganicBackSection>
      </div>

      <div className="d-block d-lg-none w-100">
          <img src={process.env.PUBLIC_URL + "/img/silvio/Silvio_2.png"} alt="silvio porro" className="w-100" />
      </div>

      <div>
          <TimeLine />
        </div>
    </>
  );
}

export default ChiSono;
