import React from "react";

import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Field, Form } from "formik";
import { useState, useEffect } from 'react';
import API from '@aws-amplify/api';
import * as mutations from "../../../graphql/mutations";

import styles from "./Form.module.scss";

let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const POLICY_URL = "https://www.termsfeed.com/live/acb5feda-34ce-4b79-8070-6494c3f043be";

const FormCustom = () => {
    const [canSubmit, setCanSubmit] = useState(false);
    const [buttonText, setButtonText] = useState('Invia');
    const [feedback, setFeedback] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [policy, setPolicy] = useState(false);

    const setButtonToLoading = () => {
      setButtonText(<img src={process.env.PUBLIC_URL + '/img/icons/loading.svg'} alt='Loading...' />);
    }

    const handleSubmit = async () => {
      await API.graphql({
        query: mutations.createContact,
          variables: {
            input: {
              fullName,
              message,
              email
            }
          }
        }
      );
    }
    
    const setButtonToTick = () => {
      setButtonText(<img src={process.env.PUBLIC_URL + '/img/icons/tick.svg'} alt='Loading...' />);
    }
    
    const setSuccessfullDeliveryMessage = () => {
      setFeedback(true);
    }

    useEffect(() => {
      if (fullName && re.test(email) && policy) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    }, [fullName, email, policy]);

    return(
      <Container>
          
          <Formik
              initialValues={{ fullName: "", message: "", email: "", policy: false }}
              onSubmit={async () => {
                setButtonToLoading();
                await handleSubmit();
                setTimeout(500);
                setButtonToTick();
                setSuccessfullDeliveryMessage();
              }}
          >

          <Form className={styles.formContainer}>
            <Row className={styles.formWrapper}>
              <Col className={styles.fieldWrapper+" col-12 col-lg-6"}><Field placeholder="nome e cognome" type="text" value={fullName} onChange={(e) => {setFullName(e.target.value)}} /></Col>
              <Col className={styles.fieldWrapper+" col-12 col-lg-6"}><Field placeholder="email" type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} /></Col>
              <Col className={styles.fieldWrapper+" col-12"}><Field placeholder="messaggio" type="text-input" value={message} onChange={(e) => {setMessage(e.target.value)}} /></Col>
              <Col className={styles.checkbox + " col-12"}><Field type="checkbox" name="policy" checked={policy} onChange={() => {setPolicy(!policy)}} /><span>Dichiaro di aver letto e compreso la <a className={styles.secondaryAnchor} href={POLICY_URL} target="_blank" rel="noreferrer">privacy policy</a> e di acconsentire al trattamento dei miei dati personali</span></Col>
              <Col className={styles.fieldWrapper+" col-12"}><button type="submit" disabled={!canSubmit}>{buttonText}</button></Col>
              <Col className={styles.feedbackMessage + ' col-12 d-flex justify-content-center align-content-center pt-3 ' + (feedback ? styles.visible : '')}>Messaggio inviato!</Col>
            </Row>
          </Form>
        </Formik>
      </Container>
    )
}

export default FormCustom;