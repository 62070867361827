import './Home.scss';

import Counters from '../../components/sections/counters/Counters'
import Contacts from '../../components/sections/contacts/Contacts'
import Reviews from '../../components/sections/reviews/Reviews';
import OrganicBackSection from '../../components/sections/organicBackSection/OrganicBackSection';

import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import API from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import { Link } from 'react-router-dom';

function Home() {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    fetchReviews()
  }, [])

  async function fetchReviews() {
    try {
      const reviewsData = await API.graphql({query: queries.listReviews})
      let reviews = reviewsData.data.listReviews.items
      reviews = reviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 3);
      setReviews(reviews)
    } catch (err) { console.log(err) }
  }

  return (
    <>
      <div className="landingContainer">
        <OrganicBackSection mobileImg={process.env.PUBLIC_URL + "/img/backgrounds/forma_mobile.svg"} img={process.env.PUBLIC_URL + "/img/backgrounds/landing.svg"}>
          <Col className={"d-none d-lg-block col-lg-5"}>
            <img className={"img-silvio"} src={process.env.PUBLIC_URL + "/img/silvio/copertina.png"} alt="silvio porro"/>
          </Col>
          <Col className={"landing-title-wrapper col-12 col-lg-7"}>
              <h1 className={"text-center"}>silvio porro</h1>
              <h2 className={"text-center"}>personal trainer</h2>
              <div className={"d-flex aling-items-center justify-content-center pt-5"}>
                <Link className={"main-link text-center"} to='/contatti'>Contattami</Link>
              </div>
          </Col>
        </OrganicBackSection>
      </div>

      <div className="d-block d-lg-none w-100">
          <img src={process.env.PUBLIC_URL + "/img/silvio/Silvio_1.png"} alt="silvio porro" className="w-100" />
      </div>

      <div className="countersContainer">
        <Counters />
      </div>
      <Reviews reviewsData={reviews} linkTo={true} cutText={true} />
      <Contacts />
    </>
  );
}

export default Home;
