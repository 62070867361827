export const photos = [
    {
      src: "/img/silvio/silvio1.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio2.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio3.webp",
      width: 6,
      height: 4
    },
    {
      src: "/img/silvio/silvio4.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio5.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio6.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio7.webp",
      width: 6,
      height: 4
    },
    {
      src: "/img/silvio/silvio8.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio9.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio10.webp",
      width: 6,
      height: 4
    },
    {
      src: "/img/silvio/silvio11.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio12.webp",
      width: 6,
      height: 4
    },
    {
      src: "/img/silvio/silvio13.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio14.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio15.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio16.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio17.webp",
      width: 4,
      height: 6
    },
    {
      src: "/img/silvio/silvio18.webp",
      width: 6,
      height: 4
    },
  ];
  