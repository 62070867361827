import React, { useState } from 'react';
import {Link} from "react-router-dom";

import { Row, Col } from 'react-bootstrap';

import styles from './NavBar.module.scss';

const NavBar = () => {
    const [active, setActive] = useState(false);

    return(
        <Row className={styles.navBarContainer + " justify-content-between"}>
            <Col className={styles.logoContainer+" col-6 col-md-3"}>
                <Row className={styles.logoWrapper+" justify-content-left"}>
                    <img src={process.env.PUBLIC_URL + '/img/logos/logo_silvio.svg'} alt="logo" />
                </Row>
            </Col>
            <Col className={" col-6 d-md-none"}>
                <Row className={"justify-content-end"}>
                    <div className={styles.navButton + (active ? " " +  styles.navButtonActive : "") + " d-block d-md-none mx-3"} onClick={() => setActive(!active)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Row>
            </Col>
            <Col className={styles.navContainer + " d-md-flex justify-content-end " + (active ? styles.navActive : styles.navInactive)}>
                <Row className={styles.navWrapper + " flex-column flex-md-row"}>
                    <Link className={styles.navLink} to="/" onClick={() => setActive(false)}>home</Link>
                    <Link className={styles.navLink} to="/chi-sono" onClick={() => setActive(false)}>chi sono</Link>
                    <Link className={styles.navLink} to="/recensioni" onClick={() => setActive(false)}>recensioni</Link>
                    <Link className={styles.navLink} to="/articoli" onClick={() => setActive(false)}>articoli</Link>
                    <Link className={styles.navLink} to="/gallery" onClick={() => setActive(false)}>gallery</Link>
                    <Link className={styles.navLink} to="/contatti" onClick={() => setActive(false)}>contatti</Link>
                </Row>
            </Col>
        </Row>
    )
}

export default NavBar;