import {FC, ReactNode} from "react";
import React from "react";
import "./App.scss";

import Amplify from 'aws-amplify'

import NavBar from '../components/sections/navbar/NavBar';
import Footer from '../components/sections/footer/Footer';

import { Controller } from 'react-scrollmagic';

import awsExports from "./../aws-exports";
Amplify.configure(awsExports);

type Props = {
    children: ReactNode
}

const App: FC<Props> = ({children}) => (
    <div className="App">
        <Controller>
            <NavBar />

            <div className="main">
                {children}
            </div>

            <Footer />
        </Controller>
    </div>
)

export default App;