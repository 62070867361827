//import GalleryLib from "react-photo-gallery";
import {Gallery as _Gallery} from "react-grid-gallery";
import Container from 'react-bootstrap/Container';

import styles from './Gallery.module.scss';

import {photos} from "./photos";

function Gallery() {
  return (
    <Container className={styles.galleryContainer}>
       <_Gallery images={photos} enableImageSelection={false} />
    </Container>
  );
}

export default Gallery;
