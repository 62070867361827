import React from "react";

import { Row, Col } from 'react-bootstrap';
import {SectionContainer} from 'reveal-components'

import "./Contacts.scss";

import FormCustom from "../../reusable/form/FormCustom";

const Contact = () => {
    return (
        <SectionContainer headline={"PRONTO PER INIZIARE? CONTATTAMI"}>
            <Col className={"col-12 contact-text-container"}>
                <h2>Contattami compilando il modulo sottostante o <span>chiamami</span> al <span><a href="tel: 339-722-0538">+39 339 722 0538</a></span></h2>
            </Col>
            <Row className={"align-items-center map-container"}>
                <Col className="col-12">
                    <FormCustom />
                </Col>
            </Row>
        </SectionContainer>
    )
}

export default Contact;
