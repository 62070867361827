import { Modal } from 'react-bootstrap';

const CertificationModal = (props) =>  {
    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.headline}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <img src={props.src} alt="Immagine del certificato" className={'w-100'} />
            </Modal.Body>
        </Modal>
    );
}

export default CertificationModal;