import { Link } from 'react-router-dom'
import AlternateSectionContainer from '../../reusable/alternatesectioncontainer/AlternateSectionContainer'
import CustomCard from '../../reusable/customcard/CustomCard'

import { Row } from 'react-bootstrap';

import styles from './Reviews.module.scss'

const Reviews = (props) => {
    const reviewsData = props.reviewsData

    const cutText = (text) => {
        if (text.length <= 256)
            return text
        else
            return (<p>
                    {text.substring(0, 256) + "...\n"} 
                    <Link className={styles.secondaryAnchor} to="/recensioni">Continua a leggere</Link>
                </p>)
    }

    return (
        <>
            <AlternateSectionContainer 
                headline={props.headline ? props.headline : "cosa si dice in giro?"}
                childrenClassName={styles.cardWrapper}>

                {
                    reviewsData.map((review, index) => {
                        const text = props.cutText ? cutText(review.text) : review.text
                        return (
                            <CustomCard headline={
                                <span>
                                    <img src="img/user.svg" className={styles.icon} alt="user icon" />
                                    {review.fullName}
                                </span>
                            }
                                paragraph={text}
                                key={index} />
                        )}
                    )
                }
            </AlternateSectionContainer>

            {props.linkTo && <Row>
                <Link className={styles.anchor+" text-center"} to="/recensioni">Leggi tutte le recensioni</Link>
            </Row>}
        </>
    )
}

export default Reviews