import './Contatti.scss';

import Contacts from '../../components/sections/contacts/Contacts'

function Contatti() {
  return (
    <>
      <Contacts />
    </>
  );
}

export default Contatti;
