import React from 'react';

import styles from './OrganicBackSection.module.scss';
import { Row, Col } from 'react-bootstrap';
import { Scene } from 'react-scrollmagic';

const organicBackSection = (props) => {
    return (
        <Scene classToggle={styles.visible} triggerHook={0.9} reverse={false}>
            <Row className={styles.backContainer}>
                <img id={"clipping-back"} className={styles.imgBack+" d-none d-lg-block"} src={props.img} alt="background"/>
                <Col>
                    <Row className={styles.contentWrapper+" align-items-center"}>
                        {props.children}
                    </Row>
                </Col>
            </Row>
        </Scene>
    )
}

export default organicBackSection;