import { Card } from 'reveal-components'
import styles from './CustomCard.module.scss'

const CustomCard = (props) => {
    return (
        <Card headline={props.headline}
            paragraph={props.paragraph}
            tilt={false}
            glassmorphism={false}
            className={styles.card} />
    )
}

export default CustomCard;