
import styles from './Article.module.scss';

const Article = (props) =>{
    return (
        <article className={'d-flex flex-column flex-md-row pt-5'}>
                {props.imgLeft && props.imgSrc && <div className={'d-flex align-content-center col-12 col-md-4'}>
                    <picture>
                        <source srcSet={props.imgSrc} type="image/webp" />
                        <img className={styles.articleImg} src={props.fallbackSrc} alt="article related"/>
                    </picture>
                </div>}
                <div className={'col-12 col-md-8'}>
                    <h2 className={styles.articleH2}>{props.headline}</h2>
                    <hr className={styles.articleHr} />
                    <p style={{whiteSpace: "pre-wrap"}}>
                        {props.text}
                    </p>
                </div>
                {!props.imgLeft && props.imgSrc && <div className={'d-flex align-content-center col-12 col-md-4'}>
                    <picture>
                        <source srcSet={props.imgSrc} type="image/webp" />
                        <img className={styles.articleImg} src={props.fallbackSrc} alt="article related"/>
                    </picture>
                </div>}
            </article>
    )
}

export default Article;