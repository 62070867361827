import { Route, Switch } from "react-router-dom";

import App from "./pages/App.tsx";

import Home from "./pages/home/Home";
import ChiSono from "./pages/chiSono/ChiSono";
import Recensioni from "./pages/recensioni/Recensioni";
import Gallery from "./pages/gallery/Gallery";
import Contatti from "./pages/contatti/Contatti";
import Articoli from "./pages/articoli/Articoli";

import Error404 from "./pages/404/Error404";

const AppRoutes = () => (
    <App>
        <Switch>
            <Route path="/" component={Home} exact></Route>
            <Route path="/chi-sono" component={ChiSono} exact></Route>
            <Route path="/recensioni" component={Recensioni} exact></Route>
            <Route path="/articoli" component={Articoli} exact></Route>
            <Route path="/gallery" component={Gallery} exact></Route>
            <Route path="/contatti" component={Contatti} exact></Route>
            <Route component={Error404}></Route>
        </Switch>
    </App>
)

export default AppRoutes;