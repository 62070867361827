import React from "react";

import { Row } from 'react-bootstrap';

import styles from "./Footer.module.scss"

const Footer = () => {
    return (
        <Row className={styles.footerContainer}>
            <span>© Copyright 2021 Silvio Porro. All rights reserved. {/*| <a href="#">Privacy Policy </a>*/}</span>
        </Row>
    )
}

export default Footer;