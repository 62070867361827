import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes';
import ScrollToTop from './pages/ScrollToTop';

ReactDOM.render(<Router><ScrollToTop /><AppRoutes /></Router>, document.getElementById('root'));

reportWebVitals();
