import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Row, Col } from 'react-bootstrap';

import styles from "./Counter.module.scss";

const Counter = (props) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return(
        <Col className={styles.counterWrapper}>
            <Row className={styles.countNumber+" text-center align-items-center"}>
                <Col className="align-items-center">
                    <FontAwesomeIcon icon={props.ico} className={styles.countIco} />
                    <CountUp start={viewPortEntered ? null : props.start} end={props.end} duration={props.duration} redraw={true}>
                        {({ countUpRef }) => {
                            return(
                            <VisibilitySensor active={!viewPortEntered} onChange={isVisible => {
                                if (isVisible) {
                                  setViewPortEntered(true);
                                }
                              }} delayedCall>
                                <span ref={countUpRef} />
                            </VisibilitySensor>
                            )
                        }}
                    </CountUp>
                </Col>
            </Row>
            <p className={styles.countText+" text-center"}>{props.text}</p>
        </Col>
    )
}

Counter.defaultProps = {
    duration: 2
};

export default Counter;