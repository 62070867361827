export const cert = [
    {
        title: " 12° Trofeo Ginnic Life",
        desc: " 1° classificato ",
        color: "#7a1d1d",
        date: "1995"
    },
    {
        title: "13° Trofeo Ginnic Life",
        desc: "2° classificato",
        color: "#7a1d1d",
        date: "1996"
    },
      {
        title: " Campionato Interprovinciale  I.F.B.B.",
        desc: "2° classificato",
        color: "#7a1d1d",
        date: "1997"
    },
     {
        title: " Campionato Lombardo Veneto I.F.B.B. ",
        desc: "1° classificato",
        color: "#7a1d1d",
        date: "2001"
    },
       {
        title: " Campionato Lombardo Veneto I.F.B.B. ",
        desc: "1° classificato",
        color: "#7a1d1d",
        date: "2003"
    },
   {
        title: " Federazione Italiana Fitness ",
        desc: "Master: programmazione e tecniche dall'enamento per ipertrofia",
        color: "#1D497A",
        date: "2005",
        photo: "img/scan/2005/2005_masterprogrammazione_e_tecniche_d_all'enamento_per_ipertrofia.jpeg"
    }, 
      {
        title: " Federazione Italiana Fitness ",
        desc: "Specializzato in: BodyBuilding, Circuit Training, Cardiofitness",
        color: "#1D497A",
        date: "2005",
        photo: "img/scan/2005/bodybuilding_circuit_training_allìenamento_ipertrofia.jpeg"
    },
       {
        title: " Attestato di partecipazione al master",
        desc: " allenamento natural di bodybuilding",
        color: "#1D497A",
        date: "2006",
        photo: "img/scan/2006/allenamento_natural_bodybuilding.jpeg"
    },
    {
        title: " Selezioni per Campionato Italiano A.I.N.B.B. ",
        desc: "5° classificato",
        color: "#7a1d1d",
        date: "2006"
    },
      {
        title: " Campionato Italiano A.I.N.B.B ",
        desc: "Finalista",
        color: "#7a1d1d",
        date: "2006"
    },
    {
        title: " Attestato di partecipazione al master",
        desc: " forza e prestazione",
        color: "#1D497A",
        date: "2007",
        photo: "img/scan/2007/master_forza_e_prestazione.jpeg"
    },
    {
        title: " Selezioni per Campionato Italiano A.I.N.B.B. ",
        desc: "6° classificato",
        color: "#7a1d1d",
        date: "2007"
    },
    {
        title: " Campionato Italiano A.I.N.B.B ",
        desc: "Finalista",
        color: "#7a1d1d",
        date: "2007"
    },
    {
        title: " Selezioni per Campionato Italiano A.I.N.B.B ",
        desc: "6° classificato",
        color: "#7a1d1d",
        date: "2008"
    },
    {
        title: " Selezioni per Campionato Italiano F.I.B.B.N. ",
        desc: "2° classificato",
        color: "#7a1d1d",
        date: "2008"
    },
    {
        title: " Selezioni per Campionato Italiano I.N.B.A. ",
        desc: "2° classificato",
        color: "#7a1d1d",
        date: "2008"
    },
    {
        title: " Campionato Italiano I.N.B.A. ",
        desc: "4° classificato",
        color: "#7a1d1d",
        date: "2008"
    },
   {
        title: " Selezioni per Campionato Italiano F.I.B.B.N. ",
        desc: "6° classificato",
        color: "#7a1d1d",
        date: "2010"
    }, 
    {
        title: " Campionato Italiano I.N.B.A. ",
        desc: "5° classificato",
        color: "#7a1d1d",
        date: "2010"
    },   
    {
        title: " Master ",
        desc: "Muscle detox/vit.D Equilibrio pH",
        color: "#1D497A",
        date: "2011",
        photo: "img/scan/2011/muscle_detox_vit_d_equilibrio_Ph.jpeg"
    },
    {
        title: " Official Certification ",
        desc: "The diet we evolved to eat",
        color: "#1D497A",
        date: "2011",
        photo: "img/scan/2011/the_diet_we_evolved_to_eat.jpeg"
    },
    {
        title: " BIIO Certification ",
        desc: "Corso 2°liv. Personal trainer advanced",
        color: "#1D497A",
        date: "2011",
        photo: "img/scan/2011/corso_ii_liv_pt_advanced.jpeg"
    },
    {
        title: " Focus Master ",
        desc: "Squat & Stacco - FMS",
        color: "#1D497A",
        date: "2012",
        photo: "img/scan/2012/squat&stacco_fms.jpeg"
    },
    {
        title: " Traumatologia & Nutrizione ",
        desc: "Schiena, Ginocchio, Taping",
        color: "#1D497A",
        date: "2012",
        photo: "img/scan/2012/traumatologia_&_nutrizione.jpeg"
    },
    {
        title: " Summer workshop ",
        desc: "Evo diet",
        color: "#1D497A",
        date: "2012",
        photo: "img/scan/2012/summer_workshop_evo_diet.jpeg"
    },
    {
        title: " Attestato di partecipazione ",
        desc: "Master Trigger Point",
        color: "#1D497A",
        date: "2012",
        photo: "img/scan/2012/master_trigger_point.jpeg"
    },
    {
        title: " Summer Workshop ",
        desc: "Aggiornamento Evo diet",
        color: "#1D497A",
        date: "2013",
        photo: "img/scan/2013/aggiornamento_evo_diet.jpeg"
    },
    {
        title: " Certificato ",
        desc: "Aggiornamento metodica Heavy Duty",
        color: "#1D497A",
        date: "2013",
        photo: "img/scan/2013/certificato_allenamento_heav_duty.jpeg"
    },
    {
        title: " Diploma nazionale ",
        desc: "Functional training 1°liv.",
        color: "#1D497A",
        date: "2014",
        photo: "img/scan/2014/diploma_nazionale_istruttore_funcional_training_1_livello.jpeg"
    },
    {
        title: " Attestato di partecipazione corso  ",
        desc: "Biomeccanica dell'esecizio: squat, panca, stacco",
        color: "#1D497A",
        date: "2014",
        photo: "img/scan/2014/biomeccanica_dell_esercizio_squat_panca_stacco.jpeg"
    },
    {
        title: " Attestato di partecipazione corso  ",
        desc: "Natural peaking",
        color: "#1D497A",
        date: "2014",
        photo: "img/scan/2014/natural_peaking.jpeg"
    },
    {
        title: " Attestato di partecipazione workshop  ",
        desc: "Enter the barbell",
        color: "#1D497A",
        date: "2014",
        photo: "img/scan/2014/enter_the_barrel.jpeg"
    },
    {
        title: " Attestato di partecipazione workshop  ",
        desc: "Programmazione e metodiche di allenamento per forza e ipertrofia",
        color: "#1D497A",
        date: "2014",
        photo: "img/scan/2014/pregrammazione_metodiche_di_allenamento_per_forza_e_ipertrofia.jpeg"
    },
    {
        title: " Campionato Nord Italia W.B.P.F ",
        desc: "3° classificato",
        color: "#7a1d1d",
        date: "2015"
    },
    {
        title: " Attestato di partecipazione corso  ",
        desc: "One day trx (Luigi Colbax)",
        color: "#1D497A",
        date: "2015",
        photo: "img/scan/2015/one_day_trx_luigi_colbax.jpeg"
    },
    {
        title: " Attestato di partecipazione workshop  ",
        desc: "Enter the bodyweight",
        color: "#1D497A",
        date: "2015",
        photo: "img/scan/2015/enter_the_bodyweight.jpeg"
    },
    {
        title: " Attestato di partecipazione workshop  ",
        desc: "Kettlebel duro e essenziale",
        color: "#1D497A",
        date: "2015",
        photo: "img/scan/2015/kettlebel_duro_e_essenziale.jpeg"
    },
    {
        title: " Attestato di partecipazione ",
        desc: "Sustainable bodybuilding training",
        color: "#1D497A",
        date: "2015"
    },
    {
        title: " Trofeo Power Night 6° classificato cat Unica H/P ",
        desc: "premio come miglior posatore",
        color: "#7a1d1d",
        date: "2015"
    },
    {
        title: " Master ",
        desc: "NBT workout",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/nbt_workout.jpeg"
    },
    {
        title: " Certificazione FIPL ",
        desc: "Diploma di strenght trainer (base)",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/fipl_strength_trainer.jpeg"
    },
    {
        title: " Certificazione ",
        desc: "Food & training 4BB",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/food_training_4bb.jpeg"
    },
    {
        title: " Certificazione ",
        desc: "Coach 4BB",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/master_coach_h4bb.jpeg"
    },
    {
        title: " Seminario ",
        desc: "Workshop Tour",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/workshop_tour.jpeg"
    },
    {
        title: " Camp teorico - pratico ",
        desc: "Protocollo ibrido HD/ZT",
        color: "#1D497A",
        date: "2016",
        photo: "img/scan/2016/protocollo_ibrido_hd.jpeg"
    },
    {
        title: " Master ",
        desc: "Old School for new bodybuilding",
        color: "#1D497A",
        date: "2017",
        photo: "img/scan/2017/old_school_for_new_body_buinding.jpeg"
    },
    {
        title: " Master ",
        desc: "Postural & phisio for BB",
        color: "#1D497A",
        date: "2017"
    },
    {
        title: " LCX health & fitness ",
        desc: "One day mobility",
        color: "#1D497A",
        date: "2018",
        photo: "img/scan/2018/open_day_mobiity.jpeg"
    },
    {
        title: " Attestato di partecipazione ",
        desc: "Forza massimale e crescita muscolare",
        color: "#1D497A",
        date: "2019",
        photo: "img/scan/2019/Forza_massimale_e_crescita_muscolare.jpeg"
    },
    {
        title: " Trofeo Ticino 1° classificato cat. fino a 70 kg ",
        desc: "3° classificato over 40",
        color: "#7a1d1d",
        date: "2019",
        photo: "img/scan/2019/trofeo_ticino.jpeg"
    },
    {
        title: " Trofeo Power night 2° classificato cat. leggeri ",
        desc: "5° classificato over 40 - miglior poser",
        color: "#7a1d1d",
        date: "2019"
    },
    {
        title: " Workshop ",
        desc: "The complete bodybuilding contest prep",
        color: "#1D497A",
        date: "2019",
        photo: "img/scan/2019/complete_bodybuinding.jpeg"
    },
    {
        title: " Campionato Nord Italia W.B.P.F ",
        desc: "4° Classificato H/P fino a 1.70 mt, 1° cat. leggeri",
        color: "#7a1d1d",
        date: "2019"
    },
    {
        title: " Trofeo Delle Alpi , selezioni camp. italiano C.I.B.B. ",
        desc: "3° classificato cat. classic -3 , 3° classificato master over 40 ",
        color: "#7a1d1d",
        date: "2019"
    },
    {
        title: " Campionato Italiano C.I.B.B ",
        desc: "4° classificato Cat. Classic -3  ",
        color: "#7a1d1d",
        date: "2019"
    },
    {
        title: " Campionato Italiano W.B.P.F ",
        desc: "3° classificato cat. master over 40 , 2° classificato cat. fino a 65 kg ",
        color: "#7a1d1d",
        date: "2019"
    },
     {
        title: " Workshop ",
        desc: "Movement restoring advanced",
        color: "#1D497A",
        date: "2020",
        photo: "img/scan/2020/movement_restoring_advanced.jpeg"
    },    
]
