import { React, useState } from "react";

import "./TimeLine.scss";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {cert} from "./Cert&Comp";
import CertificationModal from "../../reusable/certiticationmodal/CertificationModal";


const TimeLine = () => {
    const [opened, setOpened] = useState(false);
    const [displayCertSize, setdisplayCertSize] = useState(5);
    const [openModal, isOpenModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [currentHeadline, setCurrentHeadline] = useState(null);

    const handleClose = () => {isOpenModal(false)};

    return(
        <>
            <VerticalTimeline lineColor={"#1D497A"}>
                {cert.slice((cert.length-displayCertSize), cert.length).reverse().map((cert, index) => {
                    return (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date={cert.date}
                            iconStyle={{ background: cert.color, color: '#fff' }}
                            key={index}>
                            <h3 className="vertical-timeline-element-title">{cert.title}</h3>
                            <p>{cert.desc}</p>
                            {cert.photo && <button onClick={() => {setCurrentImage(cert.photo); setCurrentHeadline(cert.title); isOpenModal(true);}} className='anchor-t'>Mostra</button>}
                        </VerticalTimelineElement>
                    )
                })}
                {!opened && 
                    <div className={"show-more-cert-container d-flex justify-content-left justify-content-lg-center"}>
                        <a onClick={(e) => {setOpened(true); setdisplayCertSize(cert.length)}}>
                            Mostra altri elementi
                        </a>
                    </div>
                }
            </VerticalTimeline>
            <CertificationModal 
                show={openModal}
                onHide={handleClose}
                headline={currentHeadline}
                src={process.env.PUBLIC_URL + currentImage}
            />
        </>
    )
}

export default TimeLine;